<template>
	<div>
		<div class="quiz-top-head d-flex justify-space-between align-items-center">

			<span>{{ quizTitle }}</span>
			<span @click="$emit('addNewQuestion')"><v-icon>mdi-plus</v-icon></span>
		</div>

		<QuizEditorQuestions
				:questions="questions"
				@save="$emit('save', $event)"
				@remove="$emit('remove', $event)"
				@changeIndex="$emit('changeIndex', $event)"
			></QuizEditorQuestions>

	</div>
	<!-- <section>
		<section class="quizes-editor-content">
			<v-row>
				<v-col cols="8">
					<h2 class="h3">{{ quizTitle }}</h2>
				</v-col>
				<v-col cols="4"
					><span
						@click="$emit('addNewQuestion')"
						class="course-editor-content__plus"
					>
						<v-icon>mdi-plus</v-icon>
					</span></v-col
				>
			</v-row>

			<QuizEditorQuestions
				:questions="questions"
				@save="$emit('save', $event)"
				@remove="$emit('remove', $event)"
				@changeIndex="$emit('changeIndex', $event)"
			></QuizEditorQuestions>
		</section>
	</section> -->
</template>
<script>
import QuizEditorQuestions from "@/views/admin/quiz-editor/QuizEditorQuestions.vue";
export default {
	name: "QuizEditorContent",
	props: {
		quiz: {
			type: Object,
			required: true
		},
		quizTitle: {
			type: String,
			required: true
		}
	},
	components: {
		QuizEditorQuestions
	},
	computed: {
		questions() {
			return _.orderBy(this.quiz.questions, 'question.sort_order')
			// if (!!this.quiz?.questions === false) return [];
			// return this.quiz?.questions;
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			}
		};
	}
};
</script>

<style></style>

